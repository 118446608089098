.hero {
  margin: 3rem 0;
  color: #fff;
}

.hero a {
  color: #fff;
}

.hero .box {
  padding: 3rem;
  position: relative;
}

.hero img {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  object-fit: cover;
}

.hero .container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 6.6vh);
  gap: 1rem;
}

.hero .box:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 9;
}

.hero .box:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}

.hero .box:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 9;
}

.hero .box:nth-child(4) {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 9;
}

.hero .text {
  position: absolute;
  bottom: 3rem;
}

.hero .text h1 {
  max-width: max-content;
  font-weight: 500;
  margin: 1rem 0;
  padding: 0 0.5rem;
  font-size: 1.6rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

.hero .text h1:nth-child(2) {
  font-size: 10rem;
}

.hero .author span {
  font-size: 1.4rem;
  margin-right: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

.hero .box:nth-child(1) h1 {
  font-size: 2.8rem;
}

@media screen and (max-width: 1000px) {
  .hero .container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(18, 9vh);
    gap: 1rem;
  }

  .hero .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .hero .box:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 10;
  }

  .hero .box:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 15;
  }

  .hero .box:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
  }

  .hero .box:nth-child(1) h1 {
    font-size: 2rem;
  }
}
