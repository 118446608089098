main {
  margin-top: 5rem;
}

.details {
  padding-right: 5rem;
}

.details .title {
  font-size: 4rem;
  line-height: 6rem;
  font-weight: 700;
}

.details .date {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 2rem 0;
}

.details .date p {
  text-transform: capitalize;
  margin-right: 1rem;
}

.details .date label {
  color: grey;
  cursor: auto;
}

.details .social {
  display: flex;
  margin-bottom: 2rem;
}

.details .social .socBox {
  margin-right: 2rem;
}

.details img {
  width: 100%;
  height: 60rem;
  object-fit: cover;
  margin: 3rem 0;
}

.desc p {
  margin: 2rem 0;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}

.singleAd img {
   height: 10rem;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .details {
    padding-right: 0;
  }

  .details img {
    width: 100%;
  }

  .social {
    justify-content: center;
    flex-wrap: wrap;
  }
}
