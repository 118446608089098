.tv .live {
  margin: 5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: #23518528 0 1.3rem 2.7rem -0.5rem,
    rgba(0, 0, 0, 0.3) 0 0.8rem 1.6rem -0.8rem;
}

.tv .searchForm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tv .searchForm input {
  flex: 2;
}

.cards .box {
  justify-content: flex-start;
  margin-bottom: 1rem;
  box-shadow: #23518528 0 1.3rem 2.7rem -0.5rem,
    rgba(0, 0, 0, 0.3) 0 0.8rem 1.6rem -0.8rem;
}

.cards .img {
  width: 40rem;
  height: 22rem;
}

.cards img {
  width: 100%;
  height: 22rem;
  object-fit: cover;
}

.cards .text {
  padding: 0 2rem;
}

.cards a {
  color: #000;
}

.cards h1 {
  font-size: 2.2rem;
}

.cards span {
  font-size: 1.6rem;
  color: grey;
}

@media screen and (max-width: 1000px) {
  .cards .img,
  .cards img {
    width: 20rem;
    height: 15rem;
  }

  .cards .text {
    width: 100%;
  }
}
